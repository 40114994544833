import React, { useEffect, useState, useCallback } from "react"
import { Box } from "@mui/material"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import theme from "theme"
import { getContent } from "api/content"
import { contentContext as ContentContext } from "contexts"
import { backgroundImage } from "assets"
import loadable from "@loadable/component"
import OAuth from "views/OAuth"
import Cookie from "js-cookie"
import config from "config"
import withClearCache from "./clearCache"

const useStyles = makeStyles(() => ({
  root: () => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100svh",
    width: "100%",
    minHeight: "fit-content",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    flexGrow: 1,
    CacheControl: "max-age=31536000, public",
    [theme.breakpoints.down("sm")]: {
      height: "100svh",
      padding: 0,
    },
  }),
}))
const BankIDComponent = loadable(() => import("views/BankID"))
const ChooseBankIDComponent = loadable(() => import("views/ChooseBankID"))

const ClearCacheComponent = withClearCache(MainApp)

function App() {
  return <ClearCacheComponent />
}

function MainApp() {
  const [height, setHeight] = useState(window.innerHeight)
  const [content, setContent] = useState({})
  const classes = useStyles(height)

  const handleHeight = useCallback((e) => {
    setHeight(e.target.innerHeight)
  }, [])

  function checkCookies() {
    if (Cookie.get("patient")) {
      window.location.replace(config.patientUrl)
    } else if (Cookie.get("caregiver")) {
      window.location.replace(config.caregiverUrl)
    }
  }

  function checkCookiesDelayed() {
    checkCookies()
    let attempts = 0
    const interval = setInterval(() => {
      if (attempts < 10) {
        checkCookies()
        attempts += 1
      } else {
        clearInterval(interval)
      }
    }, 250)
  }

  useEffect(() => {
    checkCookiesDelayed()

    // Add window visibility change listener for IOS Safari redirect bug
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkCookiesDelayed()
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)

    try {
      const fetchContentData = async () => {
        const data = await getContent()
        setContent(data)
      }
      fetchContentData()
    } catch (error) {
      setContent({})
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleHeight)
    return () => {
      window.removeEventListener("resize", handleHeight)
    }
  }, [handleHeight])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ContentContext.Provider value={content}>
          <Box className={classes.root}>
            <Router>
              <Routes>
                <Route path="/developer/login" element={<OAuth />} />
                <Route path="/login/" element={<BankIDComponent />} />
                <Route path="/" element={<ChooseBankIDComponent />} />
              </Routes>
            </Router>
          </Box>
        </ContentContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
