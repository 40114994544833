// eslint-disable-next-line no-unused-vars
import React, { useState } from "react"
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { handleOAuthLogin } from "api/OAuth"
import { setCookie } from "utils/setCookie"
import jwtDecode from "jwt-decode"
import config from "config"

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "start",
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      padding: theme.spacing(13, 1),
    },
  },
  button: {
    textTransform: "none",
    borderRadius: theme.spacing(3),
    height: "57px",
    lineHeight: "57px",
    verticalAlign: "middle",
    padding: 0,
    width: "100%",
    boxShadow: "none",
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "1rem",
  },
  bankIDLogo: {
    maxWidth: "1.875rem",
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  error: {
    background: "rgba(255, 255, 255, 0.7)",
    color: theme.palette.error.main,
    fontWeight: 500,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    fontSize: "0.75rem",
    lineHeight: 1.5,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0.5, 0),
    },
  },
}))

const OAuth = () => {
  const classes = useStyles()
  const [authState, setAuthState] = useState({
    loading: false,
    error: false,
    errorMessage: null,
  })

  const { googleClientId } = config

  const handleGoogleAuthError = (error = "Not allowed!") => {
    setAuthState({
      error: true,
      errorMessage: error,
    })
  }

  const handleGoogleLoginSuccess = async (response) => {
    const idToken = response?.credential
    handleOAuthLogin(idToken)
      .then((data) => {
        const { redirect, token } = data
        if (redirect && token) {
          const {
            data: { id },
          } = jwtDecode(token)
          setCookie({ data: { id, token }, url: redirect })
        } else {
          handleGoogleAuthError()
        }
      })
      .catch((error) => {
        handleGoogleAuthError(error)
      })
  }

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Box className={classes.root}>
        <GoogleLogin onSuccess={handleGoogleLoginSuccess} />
        {authState.error && authState.errorMessage && (
          <Typography className={classes.error}>
            {authState.errorMessage}
          </Typography>
        )}
      </Box>
    </GoogleOAuthProvider>
  )
}

export default OAuth
